import React,{useState} from 'react';
import GoogleMaps from '../GoogleMaps/GoogleMaps';
import 'animate.css/animate.min.css';
import './Ubicacion.scss'
const Ubicacion = (props) => {
    const {children} = props
    return ( 
        <div className="contenedor__ubicacion">
            <div className="izquierda">
                {children}
            </div>
            <div className="derecha">
                <GoogleMaps
                    origen= {props.origen}
                    destination={props.selectdestination}
                ></GoogleMaps>
            </div>
        </div>
    ); 
}
 
export default Ubicacion;