import React from 'react';
import './Portada.scss'
const Portada = (props) => {
    return ( 
        <div className="contenedor__port">
            <div className="contenedor__port_top">
                <div className="contenedor__port_top_slogan">
                    <img className="esmomento" src={props.urlSlogan} alt="slogan" />
                </div>
                <div className="contenedor__port_top_prototipo">
                    <img src={props.urlPrototipo} alt="Prototipo ibiza" />
                </div>
                <img className="bottom__triangulo1" src="./assets/imagenes/home/forma-1.png" alt="triangulo" />
                <img className="degradado" src="./assets/imagenes/home/degradado2.png" alt="degradado" />
            </div>
            <div className="contenedor__port_equipo">
                <div className="equipamiento">
                    <img src="./assets/imagenes/ibiza/textequipamiento.png" alt="Nuestro equipamiento" />
                    <img src={props.urlEquipamiento} alt="Nuestor equipamiento" />
                </div>
                <div className="amenidades">
                    <div className="top">
                        <img src={props.urlAmenidad1} alt="amenidades" />
                    </div>
                    <div className="bottom">
                        <img className="amenidades2" src={props.urlAmenidad2} alt="amenidades" />
                        {
                            props.urlAmenidad4 &&
                            <img className="amenidades4" src={props.urlAmenidad4} alt="amenidades" />
                        }
                        <img className="amenidades3" src={props.urlAmenidad3} alt="amenidades" />
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Portada;