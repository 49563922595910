import React from 'react';
import './DetallePrototipo.scss'
const DetallePrototipo = (props) => {
    console.log(props)
    const showGaleria = () =>{
        props.setGaleria(props.galerias)
        props.showCarusel(true)
    }
    const mostrar = (titulo) => {
        props.showModal(true);
        props.setTipoModal(titulo)
        props.setSubtitulo("");
        props.urlBrochure(props.brochure)
    } 
    return ( 
        <div className="contenedor__detalle">
            <div className="contenedor__detalle_fachada">
                <div className="imagen">
                    <div>
                        <img src={props.urlfachada} alt="fachada" />
                    </div>
                </div>
                <div className="descripcion">
                    <div>
                        <p>{props.desc}</p>
                    </div>
                </div>
                <div className="brochure">
                    {
                        props.brochure !== "" &&
                        <input type="button" value="BROCHURE" onClick={()=>{mostrar('DESCARGA NUESTRO BROCHURE')}}/>
                    }
                    
                </div>
            </div>
            <div className="contenedor__detalle_planta">
                <div className="imagen">
                   <div>
                        <img src={props.urlPlanta} alt="Plantas" />
                   </div>
                </div>
                <div className="opciones">
                    <div>
                        { props.galerias.length > 0 &&
                            <input type="button" value="Galería" onClick={()=> showGaleria()}/>
                        }
                        
                    </div>
                </div>
                <div className="asesor">
                    {/* <a href="#" className="hellobox-trigger">Llamar a un Asesor</a> */}
                    <a href="tel:+525550540199">Llamar a un Asesor</a>
                </div>
            </div>
        </div>
     );
}
 
export default DetallePrototipo;