import React from 'react';
// Import Swiper React components
import { Swiper } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
  
import SwiperCore, { 
    Autoplay,
    Navigation,
    Pagination
  } from 'swiper/core';
  
  // install Swiper modules
SwiperCore.use([Pagination,Autoplay ]);
const Slider = (props) => {
    const {children} = props;
    return ( 
        <>
            <Swiper 
                autoplay={{
                    "delay": 3500,
                    "disableOnInteraction": false
                }} 
                spaceBetween={30} 
                centeredSlides={true} 
                loop={true}  
                navigation={props.nav}
                className="mySwiper">
                {children}
            </Swiper>
        </>
     );
}
 
export default Slider;