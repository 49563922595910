import React,{useState} from 'react';
import './Formulario.scss';
import ReCAPTCHA from "react-google-recaptcha";
import swal from 'sweetalert';
import axios from 'axios';
import { CSSTransition } from "react-transition-group";
import Modal from '../Modal/Modal';
import Aviso from '../AvisoPrivacidad/Aviso';
import * as yup from 'yup';

const Formulario = (props) => {
    console.log("ulr",props.brochure)
    const [nombre, colocarNombre] = useState("")
    const [tel, colocarTel] = useState("")
    const [email, colocarEmail] = useState("")
    const [recaptcha, setreCaptcha] = useState("")
    const [reqname, setRequerido] = useState("")
    const [reqtel, setReqtel] = useState("")
    const [oculto, setOculto] = useState("oculto")
    const [aviso, showAviso] = useState(false);
    const [errors, setErrors] = useState([])

    const validationSchema = yup.object({
        nombre: yup.string().required("El nombre es requerido"),
        tel: yup.string()
        .matches(
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "El numero de teléfono no es válido"
        ).min(10, "Mínimo 10 dígitos")
        .max(10, "Máximo 10 dígitos").required("El teléfono es requerido"),
        email: yup.string().email(),
        recaptcha: yup.string().required("Resuelva el recaptcha")
    })
    // console.log(validationSchema,"soyvalidator")
    // console.log(errors)
    const submit = async (e) => {
        const values = {
          nombre: nombre,
          tel: tel,
          email: email,
          recaptcha: recaptcha
        }

        const isFormValid = await validationSchema.isValid(values);
        console.log('is form valid? ', isFormValid)

        if (isFormValid) {
            console.log('todo chido, enviamos el correo')
                const obj = {
                    nombre: nombre,
                    telefono: tel,
                    email: email,
                    tipo: props.titulo
                }
                     const res = await axios.post('https://davivir.com.mx/php/index.php',obj)
                    //console.log(res.data)
                    if(res.data.success){
                        swal("Enviado", "Los datos han sido enviados correctamente", "success");
                        colocarNombre("");
                        colocarEmail("");
                        colocarTel("");
                        
                        if(props.titulo === "DESCARGA NUESTRO BROCHURE"){
                            var link = document.createElement('a');
                            link.href = props.brochure
                            link.download = 'Brochure.pdf';
                            link.dispatchEvent(new MouseEvent('click'));
                        }
                        
                    }else{
                        if(res.data.error){
                            swal("Error", "Ocurrio un problema al enviar el correo", "error");
                        }
                    }
            
        } else {
            validationSchema.validate(values, { abortEarly: false }).catch((error) => {
                console.log('single error main: ', error)
                console.log('error inner: ', error.inner)
              const errors = error.inner.map((err) => {
                  console.log('err path: ', err.path)
                return err.errors[0]
              })
              setErrors(errors)
              console.log('errors: ', errors)
            })
        }
        
    }
    const onChangeName = (evt) => {
        colocarNombre(evt.currentTarget.value)
        setRequerido("")
    }
    const onChangeTel = (evt) => {
        colocarTel(evt.currentTarget.value)
        setReqtel("")
    }
    const onChangeEmail = (evt) => {
        colocarEmail(evt.currentTarget.value)
    }
    const onChange = (val) => {
        setreCaptcha(val);
    }
    const showModalAviso = () =>{
        props.showModal2(true)
        props.showSegundoM('sobreponer')
    }
    console.log(errors,'afuera')
    return ( 
        <>ss
        <div className="container__brochure">
            <div className="formulario">
                <h3>{props.titulo}</h3>
                { props.subtitulo &&
                    <h5>{props.subtitulo}</h5>
                }
                <form>
                        <div className='errores'>
                            <ul>
                            { 
                            errors.map((error)=>{
                                console.log(error)
                                return (
                                    <li>{error}</li>
                                    // <p>{error}</p>
                                )
                            })
                        }
                            </ul>

                        </div>
                    <div className="form_row">

                        <input className={reqname} type="text" placeholder="*Nombre" value={nombre} onChange={onChangeName} required/>
                        <input className={reqtel} type="text" placeholder="*Teléfono" value={tel} onChange={onChangeTel} required pattern="[0-9]{10}"/>
                    </div>
                    <div className="form_row">
                        <input type="email" placeholder="Correo Electrónico" value={email} onChange={onChangeEmail}/>
                    </div>
                    <div className="descargar">
                        <div className="recaptcha">
                        <p>*Al enviar acepta nuestros <span onClick={()=>{showModalAviso()}}>términos de privacidad</span></p>
                            <ReCAPTCHA
                                sitekey="6LcF9bceAAAAACBBdmxbdHCsvwN-M22gFCnpe98V"
                                onChange={onChange}
                            />
                            <img src="./assets/imagenes/logo-xrisanto-blanco.svg" alt="" />
                        </div>
                        <div className='button'>
                            <a onClick={()=>{submit()}}>ENVIAR</a>
                        </div>
                    </div>

                   
                </form>
            </div>
        </div>
        </>
     );
}
 
export default Formulario;