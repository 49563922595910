import Header from "./componentes/Header/Header";
import Home from "./Paginas/Home/Home";
import QuienesSomos from "./Paginas/QuienesSomos/QuienesSomos";
import Ibiza from "./Paginas/Ibiza/Ibiza";
import Palermo from "./Paginas/Palermo/Palermo";
import Montecarlo from "./Paginas/Montecarlo/Montecarlo";
import Footer from "./componentes/Footer/Footer";
import Modal from "./componentes/Modal/Modal";
import Formulario from "./componentes/Formulario/Formulario";
import { CSSTransition } from "react-transition-group";
import Contactanos from "./componentes/Contactanos/Contactanos";
import Popup from "./componentes/Popup/Popup";
import React,{useState, useEffect} from 'react';
import 'animate.css';
import WOW from 'wowjs';
import Aviso from "./componentes/AvisoPrivacidad/Aviso";
import ScrollToTop from './componentes/ScrollToTop';
import Carusel from "./componentes/Carusel/Carusel";
import Patricio from "./Paginas/Patricio/Patricio";
import {
  BrowserRouter,
  Route
} from "react-router-dom";

function App() {
  const [modal,showModal] = useState(false);
  const [aviso, showAviso] = useState(false);
  const [tipo, setTipoModal] = useState('')
  const [ocultar, setOculatr] = useState(true)
  const [segundoM, showSegundoM] = useState('')
  const [modalpopup, setpopup] = useState(false);
  const [urlImgPopup, setUrlImgPopup] = useState('./assets/imagenes/POPUP.jpg')
  const [bannerTipo, setBannerTipo] = useState('promo')
  const [subtitulo, setSubtitulo] = useState('')
  const [galeria, setGaleria] = useState(null);
  const [modalCarusel, showCarusel] = useState(false)
  const [chatIbiza, setIbiza] = useState(false)
  const [chatHome, setHome] = useState(false);
  const [brochure, setBrochure] = useState('');
  useEffect(()=>{
    // setTimeout(() => {
    //   setOculatr(false)
    //   setTimeout(() => {
    //     setpopup(true);
    //   },2000);
    // }, 1000)
    // setTimeout(() => {
    //   setpopup(true);
    // },2000);
    new WOW.WOW({
      live: false
    }).init();
  },[]); 

  return (
    <div className="App">
      <CSSTransition
            in={modalCarusel}
            timeout={200}
            classNames="show"
            unmountOnExit
        >
            <Modal showModal={showCarusel}>
              <Carusel 
                galeria={galeria}
              />
            </Modal>

      </CSSTransition>
      <CSSTransition
            in={modalpopup}
            timeout={200}
            classNames="show"
            unmountOnExit
        >
          <Popup 
            bannerTipo={bannerTipo}
            urlImgPopup= {urlImgPopup}
            showModal= {showModal}
            setTipoModal = {setTipoModal}
            setSubtitulo={setSubtitulo}
            setpopup={setpopup}/>
      </CSSTransition>
      <CSSTransition
          in={ocultar}
          timeout={200}
          classNames="show"
          unmountOnExit
      >
        <div id="preloader">
          <div id="loader"></div>
        </div>
      </CSSTransition>
      <CSSTransition
            in={aviso}
            timeout={200}
            classNames="show"
            unmountOnExit
            >
                <Modal 
                  background ={segundoM}
                  showModal={showAviso}>
                    <Aviso />
                </Modal>
        </CSSTransition>
        <CSSTransition
            in={modal}
            timeout={200}
            classNames="show"
            unmountOnExit
            >
                <Modal showModal={showModal}>
                    <Formulario 
                      brochure={brochure}
                      showSegundoM ={showSegundoM}
                      showModal2= {showAviso}
                      titulo= {tipo}
                      subtitulo={subtitulo}
                    />
                </Modal>
        </CSSTransition>

      <BrowserRouter>
      <ScrollToTop />
      <Header 
        setpopup={setpopup} 
        setUrlImgPopup={setUrlImgPopup}
        setBannerTipo={setBannerTipo}
      />
          <Route path="/" exact={true}>
            <Home 
              setOculatr={setOculatr}
              setHome ={setHome}
              setBannerTipo={setBannerTipo}
              setpopup={setpopup}
              setUrlImgPopup={setUrlImgPopup}
            /> 
          </Route>
          <Route path="/quienes-somos">  
            <QuienesSomos 
            setpopup={setpopup}
            setOculatr={setOculatr}
            />
          </Route>
          <Route path="/ibiza">  
            <Ibiza 
              setBrochure={setBrochure}
              setpopup={setpopup}
              setOculatr={setOculatr}
              setIbiza = {setIbiza}
              showCarusel={showCarusel}
              setGaleria={setGaleria}
              showModal= {showModal}
              setTipoModal = {setTipoModal}
              setSubtitulo= {setSubtitulo}
            />
          </Route>
          <Route path="/palermo">
            <Palermo
              setBrochure={setBrochure}
              setpopup={setpopup}
              setOculatr={setOculatr}
              setIbiza = {setIbiza}
              showCarusel={showCarusel}
              setGaleria={setGaleria}
              showModal= {showModal}
              setTipoModal = {setTipoModal}
              setSubtitulo= {setSubtitulo}
            />
          </Route>
          <Route path="/montecarlo">
            <Montecarlo
              setBrochure={setBrochure}
              setpopup={setpopup}
              setOculatr={setOculatr}
              setIbiza = {setIbiza}
              showCarusel={showCarusel}
              setGaleria={setGaleria}
              showModal= {showModal}
              setTipoModal = {setTipoModal}
              setSubtitulo= {setSubtitulo}
            />
          </Route>
          <Route path="/san-patricio">
            <Patricio
              setBrochure={setBrochure}
              setpopup={setpopup}
              setOculatr={setOculatr}
              setIbiza = {setIbiza}
              showCarusel={showCarusel}
              setGaleria={setGaleria}
              showModal= {showModal}
              setTipoModal = {setTipoModal}
              setSubtitulo= {setSubtitulo}
            />
          </Route>
        <Contactanos 
            showModal= {showModal}
            setTipoModal = {setTipoModal}
            setSubtitulo= {setSubtitulo}
            />
        <Footer showModal={showAviso}/>
      </BrowserRouter>

    </div>
  );
}

export default App;
