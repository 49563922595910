import React,{useEffect, useState} from 'react';
import './Proyectos.scss'
import axios from 'axios'
import SliderGroup from '../Slider/SliderGroup';
import { SwiperSlide } from "swiper/react";
import { Link } from 'react-router-dom';

const Proyectos = (props) => {
    const [proyectos, getProyectos] = useState([]);
    const [group, setNumber] = useState(4);

    useEffect(()=>{
        if(window.screen.width < 550){
            setNumber(1)
            console.log("celular")
        }else{
            if (window.screen.width  < 800) {
                setNumber(2)
                console.log("tablet")
            }else{
                if (window.screen.width  < 1130) {
                    setNumber(3)
                    console.log("tablet 2")
                }else{
                    if(window.screen.width  > 1130){
                        setNumber(4)
                        console.log("lap")
                    }
                }
            }
        }
        console.log('number',group)
        axios({
            method: 'get',
            url: 'https://adminprueba.renderinc.mx/proyectos',
            // url: 'http://localhost:8000/proyectos',
        }).then(function (response) {
            getProyectos(response.data);
            //console.log(response.data,'respuesta')
        });
    },[])

    useEffect(()=>{
        function handleResize() {
            if(window.innerWidth < 550){
                setNumber(1)
                // console.log("celular move")
            }else{
                if (window.innerWidth < 800) {
                    setNumber(2)
                    // console.log("tablet move")
                }else{
                    if (window.innerWidth < 1130) {
                        setNumber(3)
                        // console.log("tablet 2 move")
                    }else{
                        if(window.innerWidth > 1130){
                            setNumber(4)
                            // console.log("lap move")
                        }
                    }
                }

            }

        }  
        window.addEventListener('resize', handleResize)
    })
    const modalTemp = () =>{
        props.setBannerTipo('temp')
        props.setpopup(true)
        props.setUrlImgPopup('./assets/imagenes/Popup2.jpg')
    }

    return ( 
        <div className="container__proyectos">
            <img className="wow animate__animated animate__fadeIn" src="./assets/imagenes/home/patrimonio.png" alt="patrimonio" />
            <div className="container__proyectos_card">
                <SliderGroup group={group}>
                    {
                        proyectos.map((val,index)=>{
                            return (
                                <SwiperSlide key={index.toString()}>
                                    <div key={index.toString()} className="card">
                                        <img key={index.toString()} className="fondo" src={val.proyecto_portada} alt="proyecto" />
                                        <div className="card__top">
                                            <img key={index.toString()} src={val.proyecto_logo} alt="logo" />
                                        </div>
                                        <div className="card__bottom">
                                            <div className="card__bottom_precio">
                                                <p>CASAS DESDE</p>
                                                <p key={index.toString()}>${val.proyecto_precio}</p>
                                            </div>
                                            <Link to={val.proyecto_nombre}>VER MÁS</Link>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </SliderGroup>
            </div>
        </div>
     );
}
 
export default Proyectos;