import React from 'react';
import './Conocenos.scss'
import { Link } from "react-router-dom";

const Conocenos = () => {
    return ( 
        <div className="container__conocenos">
            {/* <div className="container__conocenos_back"><img src="./assets/imagenes/home/back2.png" alt="fondo" /></div> */}
            <img className="degradado" src="./assets/imagenes/home/degradado.png" alt="degradado" />
            <img className="container__conocenos_mapa wow animate__animated animate__fadeIn" src="./assets/imagenes/home/mapa2.png" alt="mapa" />
            <img className="bottom__triangulo2" src="./assets/imagenes/home/forma-2.png" alt="triangulo" />
            <div className="container__conocenos_top wow animate__animated animate__fadeInRight">
                <img src="./assets/imagenes/home/somos.png" alt="quienes somos" />
                <p>
                    <span>DAVIVIR </span>
                    forma parte de una empresa multinacional dedicada al desarrollo de proyectos inmobiliarios que crea valor a nuestros clientes, colaboradores y accionistas con pasión y excelencia.
                </p>
            </div>
            <div className="container__conocenos_bottom wow animate__animated animate__fadeIn animate__delay-1s">
                <div className="container__conocenos_bottom__anios ">
                    <img src="./assets/imagenes/home/21-anios.png" alt="21 años" />
                    <img src="./assets/imagenes/home/60-anios.png" alt="60 años" />
                </div>
                <Link to="/quienes-somos">CONÓCENOS</Link>
            </div>
        </div>
     );
}
 
export default Conocenos;