import React from 'react';
import './Principal.scss'
import Slider from '../Slider/Slider';
import { SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

const Principal = (props) => {
    const modalTemp = () =>{
        props.setBannerTipo('temp')
        props.setpopup(true)
        props.setUrlImgPopup('./assets/imagenes/Popup2.jpg')
    }
    return ( 
        <div className="container__principal">
            <img className="cuadros" src="./assets/imagenes/rectangulos.png" alt="rectangulos" />
            <div className="container__principal_slogan">
                <img className="slogan wow animate__animated animate__fadeInRight animate__delay-1s" src="./assets/imagenes/home/slogan.png" alt="slogan" />
            </div>
            {/* <div className="container__principal_back"><img src="./assets/imagenes/home/back.png" alt="fondo" /></div> */}
            <img className="bottom__triangulo1" src="./assets/imagenes/home/forma-1.png" alt="triangulo" />
            <img className="degradado" src="./assets/imagenes/home/degradado2.png" alt="degradado" />
            <div className="container__principal_carousel">
                <Slider nav={false}>
                    <SwiperSlide>
                        <div className="container__principal_carousel__slider">
                            <img src="./assets/imagenes/home/mujer_1.png" alt="mujer" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="container__principal_carousel__slider">
                            <img src="./assets/imagenes/home/mujer-2.png" alt="mujer" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="container__principal_carousel__slider">
                            <img src="./assets/imagenes/home/obrero.png" alt="obrero" />
                        </div>
                    </SwiperSlide>
                    {/* <SwiperSlide>
                        <div className="container__principal_carousel__slider">
                            <img src="./assets/imagenes/home/slider/malta-hero2.png" alt="malta" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="container__principal_carousel__slider">
                            <img src="./assets/imagenes/home/slider/presige-hero.png" alt="presige" />
                        </div>
                    </SwiperSlide> */}
                </Slider>
                <div className="raya">
                        <img src="./assets/imagenes/home/Elipse.png" alt="elipse" />
                </div>
            </div>
            <div className="container__principal_frase">
                <div className="container__principal_frase_top">
                    <img className="frase  animate__animated animate__fadeIn animate__delay-2s" src="./assets/imagenes/home/frase-casa.png" alt="frase" />
                </div>
                <div className="propiedades  animate__animated animate__fadeInRight animate__delay-1s">
                    <Link to="/san-patricio" ><img src="./assets/imagenes/home/logos-proyectos/sp2.png" alt="san patricio" /></Link>
                    <Link to="/palermo"><img src="./assets/imagenes/home/logos-proyectos/pp.png" alt="punta palermo" /></Link>
                    <Link to="/ibiza"><img src="./assets/imagenes/home/logos-proyectos/ibiza.png" alt="ibiza" /></Link>
                    <Link to="/montecarlo"><img src="./assets/imagenes/home/logos-proyectos/montecarlo.png" alt="montecarlo" /></Link>
                </div>
            </div>
        </div>
     );
}
 
export default Principal;