import React,{useEffect, useState} from 'react';
import Portada from '../../componentes/Portada/Portada';
import { CSSTransition } from "react-transition-group";
import Prototipos from '../../componentes/Prototipos/Prototipos';
import DetallePrototipo from '../../componentes/DetallePrototipo/DetallePrototipo';
import Ubicacion from '../../componentes/Ubicacion/Ubicacion';
import axios from 'axios';
import { Helmet} from 'react-helmet'
import { SwiperSlide } from "swiper/react";
import ScrollAnimation from 'react-animate-on-scroll';
const destinations = {
    ORIGEN:{
        lat: 19.647612882262038,
        lng: -99.02445945654864
    },
    CDMX: { 
        lat: 19.246460952428624,
        lng: -99.1013508686317
    },
    PACHUCA: { 
        lat: 19.927735080082126, 
        lng: -98.89169437734144
    },  
    TECAMAC: {  
        lat: 19.71118447343089,
        lng: -98.97287164804132
    },
    PLAZA: {  
            lat: 19.708401235446676,
            lng: -98.97677697679562
    },
    WALMART: {   
        lat: 19.65906994758672,
        lng: -99.0184555768544
    }
}

const Palermo = (props) => {
    const [prototipos, getPrototipos] = useState([]);
    const [galerias, getGalerias] = useState([]);
    const [showDetalle, setDetalle] = useState(false);
    const [urlfachada, setUrlFachada] = useState('');
    const [urlPlanta, setUrlPlanta] = useState('');
    const [desc, setDesc] =useState('')
    const [group, setNumber] = useState(5);
    const [activo, setActivo] = useState('')
    const [brochure, Brochure] = useState('');
    let [selectdestination, setDestination] = useState(null);

    useEffect(()=>{

        props.setIbiza(true)
        axios({
            method: 'get',
            url: 'https://adminprueba.renderinc.mx/prototipos/3'
        }).then(function (response) {
            getPrototipos(response.data);
            Brochure(response.data[0].prototipo_urlBrochure)
            if(window.screen.width < 550){
                setNumber(1)
            }else{
                if(response.data.length>=3){
                    setNumber(3)
                }else{
                    setNumber(response.data.length)
                }
            }
            const activos = response.data.filter((prototipo)=> prototipo.prototipo_estado !== "agotado")
            axios({
                method: 'get',
                url: `https://adminprueba.renderinc.mx/galerias/${activos[0].prototipos_id}`,
            }).then(function (response) {
                getGalerias(response.data);
                setUrlFachada(activos[0].prototipo_urlFachada)
                setUrlPlanta(activos[0].prototipo_urlPlanta)
                setDesc(activos[0].prototipo_descripcion)
                setDetalle(true)
                setTimeout(() => {
                    props.setOculatr(false)
                }, 1000)
            });
        });
        return () => {
            props.setOculatr(true)
            const helloboxWidghet = document.getElementById('hellobox-widget')
            const helloboxBottomBar = document.getElementById('hellobox-bottom-bar')

            if (helloboxWidghet && helloboxBottomBar) {
                helloboxBottomBar.remove();
                helloboxWidghet.remove();
            }
        }
    },[])
    

    useEffect(()=>{
        function handleResize(p) {
            if(window.innerWidth < 550){
                setNumber(1)
            }else{
                if(prototipos.length >=3){
                    setNumber(3)
                }else if(prototipos.length > 0) {
                    setNumber(prototipos.length)
                }
            }
        }
        window.addEventListener('resize', handleResize(prototipos.length))
    })
    const getGaleria = (prototipo) =>{
        if(prototipo.prototipo_estado !== "agotado"){
            axios({
                method: 'get',
                url: `https://adminprueba.renderinc.mx/galerias/${prototipo.prototipos_id}`,
            }).then(function (response) {
                getGalerias(response.data);
                setUrlFachada(prototipo.prototipo_urlFachada)
                setUrlPlanta(prototipo.prototipo_urlPlanta)
                setDesc(prototipo.prototipo_descripcion)
                Brochure(prototipo.prototipo_urlBrochure)
                setDetalle(true)
                //console.log(response,'respuesta')
            });
        }else{
            // getGalerias([]);
            // setUrlFachada('')
            // setUrlPlanta('')
            // setDesc('')
            // setDetalle(false)
        }


    }
    return ( 
        <div>
            <Helmet>
            <script  id="hbx_widget"  data-cfasync="false" type="text/javascript" src="https://hellobox.chat/dist/widget.min.js" data-token="3841717250482f3035373035562f3157385973546e513d3d"  async="async"></script>
            </Helmet>
            <Portada
                urlSlogan="./assets/imagenes/Palermo/frase2.png"
                urlPrototipo="./assets/imagenes/Palermo/hero2.png"
                urlEquipamiento="./assets/imagenes/Palermo/equi-palermo.png"
                urlAmenidad1="./assets/imagenes/Palermo/aeropuerto.png"
                urlAmenidad2="./assets/imagenes/Palermo/linea-1.png"
                urlAmenidad3="./assets/imagenes/Palermo/autopista.png"
                urlAmenidad4="./assets/imagenes/Palermo/linea4.png"
            />
            <Prototipos group={group}>
                {
                    prototipos.map((val,index)=>
                        {
                            return(
                                <SwiperSlide key={index}>
                                    <div className="card" key={index} onClick={()=>getGaleria(val)}>
                                        <img key={index} src={val.prototipo_urlPortada} alt="prototipo" />
                                    </div>
                                </SwiperSlide>
                            )
                        }
                    )
                }
            </Prototipos>
            <CSSTransition
            in={showDetalle}
            timeout={200}
            classNames="deslizar"
            unmountOnExit
            >
                <DetallePrototipo
                    showModal= {props.showModal}
                    setTipoModal = {props.setTipoModal}
                    setSubtitulo= {props.setSubtitulo}
                    showCarusel={props.showCarusel}
                    setGaleria={props.setGaleria}
                    galerias={galerias}
                    urlfachada={urlfachada}
                    urlPlanta={urlPlanta}
                    urlBrochure={props.setBrochure}
                    brochure={brochure}
                    desc={desc}
                />
            </CSSTransition>
            <Ubicacion
                origen={destinations.ORIGEN}
                selectdestination ={ selectdestination}
            >
                <div className="texto">
                    <div className="top">
                        <ScrollAnimation
                                duration={2}
                                animateOnce={true}
                                animateIn='animate__fadeIn'>
                                    <div className="direccion">
                                        <img className="palermo" src="./assets/imagenes/Palermo/logo-palermo2.png" alt="logo Palermo" />
                                        <h1>Av. Ozumbilla Esq. Av. Castilla, SN, Col. San Francisco,Tecamac, Estado de México.</h1>
                                    </div>
                                    <div className="rutas">
                                        <div className="googlemaps">
                                            <p>VER EN:</p> <a target="_blank" rel="noreferrer" href="https://goo.gl/maps/3TvtvNCowSsU4qCD7"><img src="./assets/imagenes/googlemaps.png" alt="googlemaps" /></a>
                                        </div>
                                        <div className="waze">
                                            <p>VER EN:</p> <a target="_blank" rel="noreferrer" href="https://www.waze.com/en/live-map/directions/mexico/state-of-mexico/ojo-de-agua/punta-palermo-davivir?place=ChIJewUKTLTz0YUR72SYVzuEoDI"><img src="./assets/imagenes/waze.png" alt="waze" /></a>
                                        </div>
                                    </div>
                        </ScrollAnimation>
                    </div>
                    <div className="bottom">
                        <div className="location-poi">
                            <div className="addon-lines wow">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="location-poi-items">
                                <span className="item" onClick={() => setDestination(destinations.CDMX)}>
                                <ScrollAnimation
                                    duration={2}
                                    animateOnce={true}
                                    animateIn='animate__fadeIn'>
                                    <span className="text-uppercase">CDMX</span>
                                </ScrollAnimation>
                                </span>
                                <span className="item"  onClick={() => setDestination(destinations.PACHUCA)}>
                                <ScrollAnimation
                                    duration={2}
                                    delay={300}
                                    animateOnce={true}
                                    animateIn='animate__fadeIn'>
                                    <span className="text-uppercase">PACHUCA HGO</span>
                                </ScrollAnimation>
                                </span>
                                <span className="item"  onClick={() => setDestination(destinations.TECAMAC)}>
                                <ScrollAnimation
                                    duration={2}
                                    delay={300}
                                    animateOnce={true}
                                    animateIn='animate__fadeIn'>
                                    <span className="text-uppercase">CENTRO DE TECAMAC</span>
                                </ScrollAnimation>
                                </span>
                                <span className="item" onClick={() => setDestination(destinations.PLAZA)}>
                                <ScrollAnimation
                                    duration={2}
                                    delay={100}
                                    animateOnce={true}
                                    animateIn='animate__fadeIn'>
                                    <span className="text-uppercase">PLAZA PATIO</span>
                                </ScrollAnimation>
                                </span>
                                <span className="item"  onClick={() => setDestination(destinations.WALMART)}>
                                <ScrollAnimation
                                    duration={2}
                                    delay={200}
                                    animateOnce={true}
                                    animateIn='animate__fadeIn'>
                                    <span className="text-uppercase">WALMART</span>
                                </ScrollAnimation>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Ubicacion>
        </div>
     );
}
 
export default Palermo;