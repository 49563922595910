import React, { useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker, DirectionsService,DirectionsRenderer } from '@react-google-maps/api';
/* global google */

const containerStyle = {
    width: '100%',
    height: '100%'
  };
  
const center = {
    lat: 19.889162771535993,
    lng: -98.94114755815437
};

const GoogleMaps = (props) => {
    //console.log(props)
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyD-aeOV2bIVfzy2Vyh_FtrGkKmgMSfXv2A"
    })
    const [res, setResponse] = useState(null)

    const directionsCallback = (response) => {
        //console.log(response)
    
        if (response !== null) {
          if (response.status === 'OK') {
            setResponse(response)
            // this.setState(
            //   () => ({
            //     response
            //   })
            // )
          } else {
            //console.log('response: ', response)
          }
        }
        //console.log("res",res)
    }
    return isLoaded ? ( 
            <GoogleMap
                id="mapa-rutas"
                mapContainerStyle={containerStyle}
                center={props.origen}
                zoom={17}
            >
                {
                    props.destination &&

                        <DirectionsService
                        options={{ 
                          destination: props.destination,
                          origin: props.origen,
                          travelMode: "DRIVING"
                        }}
                        // required
                        callback={directionsCallback}
                      />
                }
                {
                    res !== null && (
                        <DirectionsRenderer
                        // required
                        options={{ 
                            directions: res
                        }}
                        />
                    )
                }
                <Marker  
                            icon={{url: "./assets/imagenes/marker.png", anchor: new google.maps.Point(17, 46), scaledSize: new google.maps.Size(30, 50)}} 
                            position={{ lat: props.origen.lat, lng: props.origen.lng }} />
                { /* Child components, such as markers, info windows, etc. */ }
                <></>
            </GoogleMap>
     ) : <></>
}
 
export default GoogleMaps;