import React,{ useEffect, useState } from 'react';
import Principal from "../../componentes/Principal/Principal.js";
import Ubicaciones from "../../componentes/Ubicaciones/Ubicaciones";
import Proyectos from "../../componentes/Proyectos/Proyectos";
import Conocenos from "../../componentes/Conocenos/Conocenos";
import {Helmet} from "react-helmet";
const Home = (props) => {

    useEffect(()=>{
        //console.log("entramos home")
        props.setHome(true)
        setTimeout(() => {
            props.setOculatr(false)
        }, 1000)
        return () => {
            props.setOculatr(true)
            document.getElementById('hellobox-widget').remove()
            document.getElementById('hellobox-bottom-bar').remove()
            console.log("salimos home")
        }
    },[])

    return ( 
        <>
                <Helmet>
                    <script  id="hbx_widget"  data-cfasync="false" type="text/javascript" src="https://hellobox.chat/dist/widget.min.js" data-token="34586d4b3844495756363957623256697a59564a51413d3d"  async="async"></script>
                </Helmet>
            <Principal
                setBannerTipo={props.setBannerTipo}
                setpopup={props.setpopup}
                setUrlImgPopup={props.setUrlImgPopup}
            />
            <Ubicaciones
                setBannerTipo={props.setBannerTipo}
                setpopup={props.setpopup}
                setUrlImgPopup={props.setUrlImgPopup}
            />
            <Conocenos/>
            <Proyectos
                setBannerTipo={props.setBannerTipo}
                setpopup={props.setpopup}
                setUrlImgPopup={props.setUrlImgPopup}
            />
        </>
     );
}
 
export default Home;