import React,{useEffect, useState} from 'react';
import Portada from '../../componentes/Portada/Portada';
import { CSSTransition } from "react-transition-group";
import Prototipos from '../../componentes/Prototipos/Prototipos';
import DetallePrototipo from '../../componentes/DetallePrototipo/DetallePrototipo';
import Ubicacion from '../../componentes/Ubicacion/Ubicacion';
import axios from 'axios';
import { Helmet} from 'react-helmet'
import { SwiperSlide } from "swiper/react";
import ScrollAnimation from 'react-animate-on-scroll';
const destinations = {
    ORIGEN:{ 
        lat: 21.884271687642595,
        lng: -102.24290613960254
    },
    CENTRO: { 
        lat: 21.882850769380614,
        lng: -102.29557432409318
    },
    IMSS: {  
        lat: 21.877033782425674,
        lng: -102.2545451372787
    },  
    ALTARIA: {  
        lat: 21.92301882207393,
        lng:  -102.28974849103177
    },
    AURRERA: {  
            lat:  21.877329652329816,
            lng: -102.24031893722194
    },
    ESTADIO: {    
        lat: 21.880909316375813,
        lng: -102.2757408699222
    }
}

const Patricio = (props) => {
    const [prototipos, getPrototipos] = useState([]);
    const [galerias, getGalerias] = useState([]);
    const [showDetalle, setDetalle] = useState(false);
    const [urlfachada, setUrlFachada] = useState('');
    const [urlPlanta, setUrlPlanta] = useState('');
    const [desc, setDesc] =useState('')
    const [group, setNumber] = useState(5);
    const [brochure, Brochure] = useState('');
    let [selectdestination, setDestination] = useState(null);

    useEffect(()=>{

        props.setIbiza(true)
        axios({
            method: 'get',
            url: 'https://adminprueba.renderinc.mx/prototipos/4'
        }).then(function (response) {
            getPrototipos(response.data);
            Brochure(response.data[0].prototipo_urlBrochure)
            if(window.screen.width < 550){
                setNumber(1)
            }else{
                if(response.data.length>=3){
                    setNumber(3)
                }else{
                    setNumber(response.data.length)
                }
            }
            const activos = response.data.filter((prototipo)=> prototipo.prototipo_estado !== "agotado")
            axios({
                method: 'get',
                url: `https://adminprueba.renderinc.mx/galerias/${activos[0].prototipos_id}`,
            }).then(function (response) {
                getGalerias(response.data);
                setUrlFachada(activos[0].prototipo_urlFachada)
                setUrlPlanta(activos[0].prototipo_urlPlanta)
                setDesc(activos[0].prototipo_descripcion)
                setDetalle(true)
                setTimeout(() => {
                    props.setOculatr(false)
                }, 1000)
            });
        });
        return () => {
            props.setOculatr(true)
            const helloboxWidghet = document.getElementById('hellobox-widget')
            const helloboxBottomBar = document.getElementById('hellobox-bottom-bar')

            if (helloboxWidghet && helloboxBottomBar) {
                helloboxBottomBar.remove();
                helloboxWidghet.remove();
            }
        }
    },[])
    

    useEffect(()=>{
        function handleResize(p) {
            if(window.innerWidth < 550){
                setNumber(1)
            }else{
                if(prototipos.length >=3){
                    setNumber(3)
                }else if(prototipos.length > 0) {
                    setNumber(prototipos.length)
                }
            }
        }
        window.addEventListener('resize', handleResize(prototipos.length))
    })
    const getGaleria = (prototipo) =>{
        if(prototipo.prototipo_estado !== "agotado"){
            axios({
                method: 'get',
                url: `https://adminprueba.renderinc.mx/galerias/${prototipo.prototipos_id}`,
            }).then(function (response) {
                getGalerias(response.data);
                setUrlFachada(prototipo.prototipo_urlFachada)
                setUrlPlanta(prototipo.prototipo_urlPlanta)
                setDesc(prototipo.prototipo_descripcion)
                Brochure(prototipo.prototipo_urlBrochure)
                setDetalle(true)
                //console.log(response,'respuesta')
            });
        }else{
            // getGalerias([]);
            // setUrlFachada('')
            // setUrlPlanta('')
            // setDesc('')
            // setDetalle(false)
        }


    }
    return ( 
        <div>
            <Helmet>
            <script  id="hbx_widget"  data-cfasync="false" type="text/javascript" src="https://hellobox.chat/dist/widget.min.js" data-token="414b4d6a7345585742434a5054755a71374d753750513d3d"  async="async"></script>
            </Helmet>
            <Portada
                urlSlogan="./assets/imagenes/San patricio/frase2.png"
                urlPrototipo="./assets/imagenes/San patricio/prototipo_hero.png"
                urlEquipamiento="./assets/imagenes/San patricio/equi-patricio.png"
                urlAmenidad1="./assets/imagenes/San patricio/a-tu-alcance.png"
                urlAmenidad2="./assets/imagenes/San patricio/centro.png"
                urlAmenidad3="./assets/imagenes/San patricio/linea-verde.png"
            />
            <Prototipos group={group}>
                {
                    prototipos.map((val,index)=>
                        {
                            return(
                                <SwiperSlide key={index}>
                                    <div className="card" key={index} onClick={()=>getGaleria(val)}>
                                        <img key={index} src={val.prototipo_urlPortada} alt="prototipo" />
                                    </div>
                                </SwiperSlide>
                            )
                        }
                    )
                }
            </Prototipos>
            <CSSTransition
            in={showDetalle}
            timeout={200}
            classNames="deslizar"
            unmountOnExit
            >
                <DetallePrototipo
                    showModal= {props.showModal}
                    setTipoModal = {props.setTipoModal}
                    setSubtitulo= {props.setSubtitulo}
                    showCarusel={props.showCarusel}
                    setGaleria={props.setGaleria}
                    galerias={galerias}
                    urlfachada={urlfachada}
                    urlPlanta={urlPlanta}
                    urlBrochure={props.setBrochure}
                    brochure={brochure}
                    desc={desc}
                />
            </CSSTransition>
            <Ubicacion
                origen={destinations.ORIGEN}
                selectdestination ={ selectdestination}
            >
                <div className="texto">
                    <div className="top">
                        <ScrollAnimation
                                duration={2}
                                animateOnce={true}
                                animateIn='animate__fadeIn'>
                                    <div className="direccion">
                                        <img className="palermo" src="./assets/imagenes/San patricio/logo-colinas-sanpatricio2.png" alt="logo San patricio" />
                                        <h1>Av. Poliducto s/n Av. San Francisco de los Viveros, Col. Colinas de San Patricio. C.P. 20196</h1>
                                    </div>
                                    <div className="rutas">
                                        <div className="googlemaps">
                                            <p>VER EN:</p> <a target="_blank" rel="noreferrer" href="https://goo.gl/maps/fMn3QsPuLkhXDexe7"><img src="./assets/imagenes/googlemaps.png" alt="googlemaps" /></a>
                                        </div>
                                        <div className="waze">
                                            <p>VER EN:</p> <a target="_blank" rel="noreferrer" href="https://www.waze.com/en/live-map/directions/mexico/aguascalientes/aguascalientes/colinas-de-san-patricio-oficina-de-ventas?place=ChIJZa_icPDxKYQRJe7BvQzYAi8&utm_campaign=waze_website&utm_medium=website_menu&utm_source=waze_website"><img src="./assets/imagenes/waze.png" alt="waze" /></a>
                                        </div>
                                    </div>
                        </ScrollAnimation>
                    </div>
                    <div className="bottom">
                        <div className="location-poi">
                            <div className="addon-lines wow">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="location-poi-items">
                                <span className="item" onClick={() => setDestination(destinations.CENTRO)}>
                                <ScrollAnimation
                                    duration={2}
                                    animateOnce={true}
                                    animateIn='animate__fadeIn'>
                                    <span className="text-uppercase">CENTRO</span>
                                </ScrollAnimation>
                                </span>
                                <span className="item"  onClick={() => setDestination(destinations.IMSS)}>
                                <ScrollAnimation
                                    duration={2}
                                    delay={300}
                                    animateOnce={true}
                                    animateIn='animate__fadeIn'>
                                    <span className="text-uppercase">IMSS HOSPITAL GENERAL</span>
                                </ScrollAnimation>
                                </span>
                                <span className="item"  onClick={() => setDestination(destinations.ALTARIA)}>
                                <ScrollAnimation
                                    duration={2}
                                    delay={300}
                                    animateOnce={true}
                                    animateIn='animate__fadeIn'>
                                    <span className="text-uppercase">CENTRO COMERCIAL ALTAIRA</span>
                                </ScrollAnimation>
                                </span>
                                <span className="item" onClick={() => setDestination(destinations.AURRERA)}>
                                <ScrollAnimation
                                    duration={2}
                                    delay={100}
                                    animateOnce={true}
                                    animateIn='animate__fadeIn'>
                                    <span className="text-uppercase">BODEGA AURRERA LA NORIA</span>
                                </ScrollAnimation>
                                </span>
                                <span className="item"  onClick={() => setDestination(destinations.ESTADIO)}>
                                <ScrollAnimation
                                    duration={2}
                                    delay={200}
                                    animateOnce={true}
                                    animateIn='animate__fadeIn'>
                                    <span className="text-uppercase">ESTADO VICTORIA</span>
                                </ScrollAnimation>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Ubicacion>
        </div>
     );
}
 
export default Patricio;