import React,{ useEffect }  from 'react';
import './QuienesSomos.scss'

const QuienesSomos = (props) => {
    useEffect(()=>{
        setTimeout(() => {
            props.setOculatr(false)

        }, 1000)
        return () => {
            props.setOculatr(true)
            //console.log("salimos somos")
        }
    },[])
    return ( 
        <div>
            <div className="contenedor__portada">
                <img className="cuadros wow animate__animated animate__fadeInDown animate__delay-1s" src="./assets/imagenes/rectangulos.png" alt="rectangulos" />
                <img className="cuadros2 wow animate__animated animate__fadeInDown animate__delay-1s" src="./assets/imagenes/rectangulos.png" alt="rectangulos" />
                <div className="contenedor__portada_slogan wow animate__animated animate__fadeIn ">
                    <img className="slogan" src="./assets/imagenes/Quienes/SLOGAAN.png" alt="triangulo" />
                </div>
                <img className="degradado" src="./assets/imagenes/home/degradado.png" alt="degradado" />
                <img className="bottom__triangulo1" src="./assets/imagenes/home/forma-1.png" alt="triangulo" />
            </div>
            <div className="contenedor__concepto">
                <div className="contenedor__concepto_frase">
                    <div className="contenedor__concepto_frase_borde">
                        <div className="contenedor__concepto_frase_borde_center">
                            <h3>DAVIVIR</h3><p>forma parte de una empresa multinacional dedicada al desarrollo de proyectos inmobiliarios 
                            que crea valor a nuestros clientes, colaboradores y accionistas con pasión y excelencia.</p>
                        </div>
                    </div>
                    <div className="contenedor__concepto_frase_somos">
                        <div className="contenedor__concepto_frase_somos_center">
                            <p>
                                <strong>Somos</strong> un equipo de gente comprometida con lo que hacemos; <strong>Nos apasionan los diseños arquitectónicos 
                                de excelente calidad, de espacios generosos y bien pensados. </strong>  
                                Vivimos y entendemos lo que buscan nuestros clientes a la hora de escoger su  casa, y es por esto que nuestras 
                                viviendas y desarrollos siempre ofrecen elementos superiores a los encontrados en desarrollos o viviendas similares. 
                                Mención especial en el Premio Nacional de vivienda obtenido en el 2005.
                                Contamos con una alta solidez financiera, lo cual nos permite asegurar la más alta calidad en nuestros procesos y 
                                productos, así como garantizar nuestra permanencia en el mercado mexicano.
                            </p>
                        </div>
                    </div>
                </div>

               <div className="contenedor__concepto_mapa">
                    <img className="mapa wow animate__animated animate__fadeIn" src="./assets/imagenes/Quienes/mapa2.png" alt="mapa" />
               </div>
                <img className="tiempo wow animate__animated animate__fadeInRight" src="./assets/imagenes/Quienes/21Anios.png" alt="21 Años" />
                <img className="tiempo2 wow animate__animated animate__fadeInRight" src="./assets/imagenes/Quienes/60 Anios.png" alt="60 Años" />
            </div>
            <div className="contenedor__mision">
                <div className="mision wow animate__animated animate__fadeIn">
                    <div><img src="./assets/imagenes/Quienes/mision.png" alt="mision" /><h1>MISIÓN</h1></div>
                    <p>Nuestro firme compromiso es dar el mayor esfuerzo en la construcción de cada uno de nuestras viviendas, fundados en la indiscutible capacidad 
                    profesional de nuestro equipo de trabajo</p>
                </div>
                <div className="vision wow animate__animated animate__fadeIn animate__delay-1s">
                    <div><img src="./assets/imagenes/Quienes/vision.png" alt="vision" /><h1>VISIÓN</h1></div>
                    <p>Nuestra visión es ofrecer productos innovadores en <span>diseño</span>, de <span>calidad</span> y altas especificaciones técnicas y de materiales. Somos conscientes de lo importante que es 
                    una vivienda para cada familia mexicana y por esa razón nos esmeramos en lo que hacemos, siempre buscando crear el más <span> alto valor</span> a nuestros clientes, colaboradores y accionistas.</p>
                </div>
                <div className="valores wow animate__animated animate__fadeIn animate__delay-2s">
                    <h1>VALORES</h1>
                    <div className="iconos">
                        <div>
                            <img src="./assets/imagenes/Quienes/responsabilidad.png" alt="Responsabilidad" />
                            <h4>RESPONSABILIDAD</h4>
                        </div>
                        <div>
                            <img src="./assets/imagenes/Quienes/integridad.png" alt="Integridad.png" />
                            <h4>INTEGRIDAD</h4>
                        </div>
                        <div>
                            <img src="./assets/imagenes/Quienes/innovacion.png" alt="Inovación" />
                            <h4>INNOVACIÓN</h4>
                        </div>
                        <div>
                            <img src="./assets/imagenes/Quienes/responsabilidad.png" alt="Honestidad" />
                            <h4>HONESTIDAD</h4>
                        </div>
                        <div>
                            <img src="./assets/imagenes/Quienes/pasion.png" alt="Pasión" />
                            <h4>PASIÓN</h4>
                        </div>
                        <div>
                            <img src="./assets/imagenes/Quienes/equipo.png" alt="Trabajo en equipo" />
                            <h4>TRABAJO EN EQUIPO</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default QuienesSomos;