import React, { useState } from 'react';
import './Ubicaciones.scss';
import { Link } from "react-router-dom";
const Ubicaciones = (props) => {
    const estados = [
        "Aguascalientes",
        "Estado de México",
        "Hidalgo"
    ]
    const [show, showCity] = useState('Aguascalientes');
    // const [list, setList] = useState(estados)
    const [icono, showLogo] = useState('');

    const handleChange =(e)=> {
        // console.log("Selected!!");
        showCity(e.target.value)
        //this.setState({ fruit: e.target.value });
    }
    const handleLogo = (tipo) => {
        showLogo(tipo)
    }
    const modalTemp = () =>{
        props.setpopup(true)
        props.setBannerTipo('temp')
        props.setUrlImgPopup('./assets/imagenes/Popup2.jpg')
    }
    return ( 
        <div className="container__ubicaciones">
            <div className="container__ubicaciones_top">
                <img className="texto" src="./assets/imagenes/home/titulo.png" alt="texto" />
                <img className="mapa wow animate__animated animate__fadeInDown" src="./assets/imagenes/home/mapa.png" alt="mapa" />
                <div className="container__ubicaciones_top_filtrado">
                    <div className="container__ubicaciones_top_filtrado__filtros">
                        <div>
                            <p>Estado</p>
                            <select onChange={(e)=>handleChange(e)}>
                                {
                                    estados.map((value,index)=>{
                                        // console.log('value', index)
                                        return (
                                            <option key={index.toString()} value={value}>{value}</option>
                                        )
                                    })

                                }
                                {/* {console.log(show)} */}
                                {/* <option value="" key="">Aguascalientes</option>
                                <option value="" key="">Estado de México</option>
                                <option value="" key="">Hidalgo</option> */}
                            </select>
                        </div>
                        <div>
                            <p>Ciudad</p>
                            <select>
                                {
                                    show === "Aguascalientes" &&
                                    (<option value="" key="">Aguascalientes</option>) 
                                }
                                {
                                    show === "Estado de México" &&
                                    (<option value="" key="">Tecámac</option>) 
                                }
                                                                {
                                    show === "Hidalgo" &&
                                    (<option value="" key="">Tizayuca</option>) 
                                }
                                {/* <option value="" key="">Aguascalientes</option>
                                <option value="" key="">Tecámac</option>
                                <option value="" key="">Tizayuca</option> */}
                            </select>
                        </div>
                       <div className="button__encontrar">
                            <input onClick={()=>handleLogo(show)} type="button" value="Encontrar"/>
                       </div>
                    </div>
                    {
                            icono === "Aguascalientes" &&
                            <div className="background__red">
                                <Link to="/san-patricio"><img src="./assets/imagenes/home/logo-ori-sanpatricio.png" alt="san patricio" /></Link >
                            </div>
                        }
                                                {
                            icono === "Estado de México" &&
                            <div  className="background__red">
                                <Link to="/palermo"><img src="./assets/imagenes/home/logo-ori-punta-palermo.png" alt="punta palermo" /></Link>
                                <Link to="/montecarlo"><img src="./assets/imagenes/home/logo-ori-montecarlo.png" alt="montecarlo" /></Link>
                            </div>
                        }
                                                {
                            icono === "Hidalgo" &&
                            <div  className="background__red">
                                 <Link to="/ibiza"><img src="./assets/imagenes/home/logo-ori-bosques-de-ibiza.png" alt="ibiza" /></Link>
                            </div>
                        }
                    <div>
                        <h3>Más de <span>21,000 Hogares</span> creados y miles de sueños hechos realidad</h3>
                    </div>
                </div>
            </div>
            <div className="container__ubicaciones_bottom wow animate__animated animate__fadeInUp">
                    <img src="./assets/imagenes/home/familia.png" alt="pareja" />
                    <img className="momento" src="./assets/imagenes/home/movimiento.png" alt="momento" />
            </div>
        </div>
     );
}
 
export default Ubicaciones;