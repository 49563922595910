import React from 'react';
import './Modal.scss';

const Modal = (props) => {
    const {children} = props;
    return ( 
        <div className={`container__modal ${props.background}`}>
            <p className="close" onClick={()=>props.showModal(false)}>X</p>
            <div className={`container__modal_card ${props.isImagen}`}>
                {children}
            </div>
        </div>
     );
}
 
export default Modal;